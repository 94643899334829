import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { stylesGlobal } from "../../../utils/muiStyles";
import FloatModal from "../../../shared/components/FloatModal";
import FloatAddProductModal from "../../../shared/components/addProducts/FloatAddProduct";
import { useDispatch, useSelector } from "react-redux";
import {
  productsByVideoIdCall,
  showUserProducts,
  unAssignProductCall,
} from "../../../actions";
import { useParams } from "react-router-dom";
import { RootState } from "../../../configureStore";
import { LinkProductStyles, styles } from "./style";
import {
  Info,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButton,
  StyledTableCell,
  StyledTableHeadCell,
  StyledTableHeadRow,
  StyledTableRow,
  StyledText,
} from "../../../utils/styledComponents";
import ProductsLinkList from "./ProductsLinkList";
import { useTranslation } from "react-i18next";
import FloatAlert from "../../../shared/components/FloatAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { linkedProductStyles } from "../Analytics.tsx/linkedProducts/style";

interface IHeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface IData {
  product_clicks: string;
  thumbnail_url: string;
  title: string;
  id: string;
  item_id: number;
}

function LinkedProducts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const videoId = useParams().id;

  const [openProductModal, setOpenProductModal] = useState<boolean>(false);
  const [openNewProductModal, setOpenNewProductModal] =
    useState<boolean>(false);
  const [openExistingProductModal, setOpenExistingProductModal] =
    useState<boolean>(false);
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState("asc");
  const [openAlert, setAlert] = useState(false);
  const [unlinkId, setUnlinkId] = useState<number | null>();

  const { produtsByvideoIdData }: any = useSelector(
    (state: RootState) => state?.entities?.productByVideo
  );

  const { unassignProductData }: any = useSelector(
    (state: RootState) => state?.entities?.unassignProduct
  );

  const handleCloseExistingProductModal = () => {
    setOpenExistingProductModal(false);
  };

  useEffect(() => {
    dispatch(showUserProducts.reset());
    setProducts([]);
    dispatch(productsByVideoIdCall.request({ data: { content_id: videoId } }));
  }, []);

  useEffect(() => {
    if (Array.isArray(produtsByvideoIdData?.data)) {
      setProducts(produtsByvideoIdData.data);
    } else {
      setProducts([]);
    }
  }, [produtsByvideoIdData?.data]);

  useEffect(() => {
    if (unassignProductData?.data?.success) {
      dispatch(
        productsByVideoIdCall.request({ data: { content_id: videoId } })
      );
    }
  }, [unassignProductData?.data]);

  const filterChange = (event: any) => {
    setOrder(event.target.value);
  };

  const headCells: readonly IHeadCell[] = [
    {
      id: "product",
      numeric: false,
      disablePadding: false,
      label: "Product",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "vname",
      numeric: true,
      disablePadding: false,
      label: "Product brand name",
    },
    {
      id: "clicks",
      numeric: true,
      disablePadding: false,
      label: "Clicks",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const headCellsMobile: readonly IHeadCell[] = [
    {
      id: "product",
      numeric: false,
      disablePadding: false,
      label: "Product",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },
  ];

  const LinkedProductHead = () => {
    return (
      <TableHead>
        <StyledTableHeadRow>
          {headCells.map((headCell, index) => (
            <StyledTableHeadCell
              key={index}
              align={headCell.id === "action" ? "center" : "left"}
              id={headCell.id}
              scope="row"
            >
              {t(headCell.label)}
            </StyledTableHeadCell>
          ))}
        </StyledTableHeadRow>
      </TableHead>
    );
  };

  const LinkedProductHeadMobile = () => {
    return (
      <TableHead>
        <StyledTableHeadRow
          sx={linkedProductStyles.headRow}
        >
          {headCellsMobile.map((headCell, index) => (
            <StyledTableHeadCell
              key={index}
              align={headCell.id === "action" ? "center" : "left"}
              id={headCell.id}
              scope="row"
            >
              {t(headCell.label)}
            </StyledTableHeadCell>
          ))}
        </StyledTableHeadRow>
      </TableHead>
    );
  };

  const handleUnlinkProduct = () => {
    dispatch(
      unAssignProductCall.request({
        data: {
          item_ids: [unlinkId],
          content_id: videoId,
        },
      })
    );
  };

  const parseCustomProductBrandName = (row:any) => {
    if (row?.custom == null) {
      return {};
    }
    if (typeof row?.custom === "string") {
      try {
        return JSON.parse(row?.custom);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return {};
      }
    }
    return row?.custom;
  }

  useEffect(() => {
    dispatch(productsByVideoIdCall.request({ data: { content_id: videoId } }));
  }, []);

  return (
    <>
      <Box sx={LinkProductStyles.wrapperBox}>
        <Button
          startIcon={<span>+</span>}
          variant="contained"
          sx={{
            ...stylesGlobal.containedBtn,
            padding: "5px 20px",
            fontWeight: "14px",
          }}
          onClick={() => setOpenProductModal(true)}
        >
          {t("Add product")}
        </Button>

        <FloatModal
          modalTitle={"Add product"}
          open={openProductModal}
          onClose={() => setOpenProductModal(false)}
          modalBody={
            <AddProductHook
              onExistingProductClick={() => setOpenExistingProductModal(true)}
              onClose={() => setOpenProductModal(false)}
              onNewProductClick={() => setOpenNewProductModal(true)}
            />
          }
        />

        <FloatModal
          modalTitle={t("Link existing product")}
          open={openExistingProductModal}
          onClose={handleCloseExistingProductModal}
          // onClose={() => setOpenExistingProductModal(false)}
          modalBody={
            <ProductsLinkList onClose={handleCloseExistingProductModal} />
          }
        />
        {/* <FloatAddProductModal
          open={openNewProductModal}
          onClose={() => setOpenNewProductModal(false)}
        /> */}
        {openNewProductModal && (
          <FloatAddProductModal
            open={openNewProductModal}
            onClose={() => setOpenNewProductModal(false)}
            productType="linking"
          />
        )}
      </Box>
      {produtsByvideoIdData?.isLoading || unassignProductData?.isLoading ? (
        <Box sx={LinkProductStyles.loaderBox}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isMobile ? (
            <>
              {products && products.length > 0 ? (
                <TableContainer
                  className="overflow-y-tbl"
                  sx={LinkProductStyles.tableContainer}
                >
                  <Table
                    sx={LinkProductStyles.tableContainer.table}
                    aria-labelledby="tableTitle"
                  >
                    <LinkedProductHead />
                    <TableBody>
                      {products.map((row: IData, index: number) => {
                        const customData = parseCustomProductBrandName(row)
                        return (
                          <StyledTableRow
                            key={index}
                            hover
                            role="checkbox"
                            sx={LinkProductStyles.tableContainer.rowStyle}
                          >
                            <StyledTableCell align="left">
                              <img
                                src={row.thumbnail_url}
                                style={{
                                  height: "90px",
                                  width: "90px",
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledText
                                sx={LinkProductStyles.tableContainer.titleBox}
                              >
                                {row.title ?? "-"}
                              </StyledText>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledText
                                sx={LinkProductStyles.tableContainer.titleBox}
                              >
                                {customData.v_name ?? "-"}
                              </StyledText>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledText>
                                {row.product_clicks ?? "-"}
                              </StyledText>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <StyledButton
                                onClick={() => {
                                  setUnlinkId(row.item_id);
                                  setAlert(true);
                                }}
                                sx={linkedProductStyles.buttonStyle}
                              >
                                {t("Unlink")}
                              </StyledButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Info> {t("No records found.")} </Info>
              )}
            </>
          ) : (
            <>
              {products && products.length > 0 ? (
                <TableContainer
                  className="overflow-y-tbl"
                  sx={LinkProductStyles.tableContainer}
                >
                  <Table
                    sx={LinkProductStyles.tableContainer.table}
                    aria-labelledby="tableTitle"
                  >
                    <LinkedProductHeadMobile />
                    <TableBody>
                      {products.map((row: IData, index: number) => {
                        const customMobData = parseCustomProductBrandName(row)
                        return (
                          <StyledAccordion sx={linkedProductStyles.accordionStyle}>
                            <StyledAccordionSummary
                              sx={linkedProductStyles.accordionSummary}
                              onClick={(e) => e.stopPropagation()}
                              className=" overflow-x-contain"
                              expandIcon={<ArrowDropDownIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <StyledTableRow
                                key={index}
                                hover
                                role="checkbox"
                                sx={
                                  LinkProductStyles.tableContainer
                                    .rowStyleMobile
                                }
                              >
                                <StyledTableCell
                                  align="left"
                                  sx={linkedProductStyles.tablecell}
                                >
                                  <img
                                    src={row.thumbnail_url}
                                    style={{
                                      height: "88px",
                                      width: "88px",
                                      objectFit: "cover",
                                    }}
                                    alt=""
                                  />
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  sx={linkedProductStyles.StyledTableCell}
                                >
                                  <StyledText
                                    sx={
                                      LinkProductStyles.tableContainer.titleBox
                                    }
                                  >
                                    {row.title ?? "-"}
                                  </StyledText>
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  sx={linkedProductStyles.cellStyle}
                                >
                                  <StyledButton
                                    onClick={() => {
                                      setUnlinkId(row.item_id);
                                      setAlert(true);
                                    }}
                                    sx={linkedProductStyles.styleButtonStyle}
                                  >
                                    {t("Unlink")}
                                  </StyledButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                              <Grid
                                sx={linkedProductStyles.gridStyle}
                              >
                                <StyledText sx={linkedProductStyles.detailKeyStyle}>{t("Clicks")}:</StyledText>
                                <StyledTableCell align="left">
                                  <StyledText>
                                    {row.product_clicks ?? "-"}
                                  </StyledText>
                                </StyledTableCell>
                              </Grid>
                              <Grid
                                sx={linkedProductStyles.gridStyle}
                              >
                                <StyledText sx={linkedProductStyles.detailKeyStyle}>{t("Product brand name")}:</StyledText>
                                <StyledTableCell align="left">
                                  <StyledText>
                                    {customMobData.v_name ?? "-"}
                                  </StyledText>
                                </StyledTableCell>
                              </Grid>
                            </StyledAccordionDetails>
                          </StyledAccordion>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Info> {t("No Records Found.")} </Info>
              )}
            </>
          )}
        </>
      )}

      {/* t("Are you sure you want to unlink this product?"), */}
      <FloatAlert
        open={openAlert}
        onClose={() => {
          setAlert(false);
        }}
        alert={t("Are you sure you want to unlink this product? All PYVs will be removed.")}
        actionBtn="Submit"
        action={() => {
          handleUnlinkProduct();
          setUnlinkId(null);
          setAlert(false);
        }}
      />
    </>
  );
};

export default LinkedProducts;

const AddProductHook = (props: any) => {
  const { onExistingProductClick, onClose, onNewProductClick } = props;
  const { t } = useTranslation();

  return (
    <Stack width={"100%"} justifyContent={"center"} alignItems={"center"}>
      <Button
        variant="contained"
        onClick={() => {
          onExistingProductClick();
          onClose();
        }}
        startIcon={<span>+</span>}
        sx={styles.hookBtn}
      >
        {t("Link existing product")}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          onNewProductClick();
          onClose();
        }}
        startIcon={<span>+</span>}
        sx={styles.unHookBtn}
      >
        {t("Add new product")}
      </Button>
    </Stack>
  );
};
