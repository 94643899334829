/* eslint-disable */
import { Box, Button, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Info, StyledText } from "../../../utils/styledComponents";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../configureStore";
import { editVideoAuthCall } from "../../../actions";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import { videoDetailStyle } from "./styles";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";

const PyvVideo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const { editVideoAuthResponse }: any = useSelector(
    (state: RootState) => state?.entities?.editVideoAuth
  );

  const data = editVideoAuthResponse?.data;

  const { videoDetails } = useSelector(
    (state: RootState) => state?.entities?.videoDetail
  );

  const videoInfo = videoDetails?.data?.data?.response;

  useEffect(() => {
    dispatch(editVideoAuthCall.request({ data: { content_id: params?.id } }));
  }, []);

  const editVideo = async () => {
    if (videoInfo?.status !== 2 && videoInfo?.status !== 3) {
      Swal.fire({
        icon: "info",
        title: t("Video Encoding In Progress"),
        text: t(
          "Your video is currently being encoded, this can take several minutes, please try again later."
        ),
      });
      return;
    }
    try {
      let info = {
        content_id: params?.id,
        tig_edit_token: data?.data?.tig_edit_token,
        back_url: window.location,
      };
      redirectPost(
        window.location.origin?.startsWith("http://localhost") ||
          window.location.origin == "https://stage.pyvit.com"
          ? "https://cms.dev.pyvit.com/api/v1/tigedit/autotracking"
          : "https://cms.pyvitapp.com/api/v1/tigedit/autotracking",
        info
      );
    } catch (err) {
      console.error(err);
    }
  };

  const redirectPost = (url: any, data: any) => {
    let form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = url;
    for (let name in data) {
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      input.value = data[name];
      form.appendChild(input);
    }
    form.submit();
  };

  return (
    <Box sx={videoDetailStyle.pyvsContainer}>
      <StyledText>{t("Click below to add PYVs.")}</StyledText>
      {!editVideoAuthResponse?.isLoading && !editVideoAuthResponse?.isError ? (
        <IconButton sx={videoDetailStyle.addPyvButton} onClick={editVideo}>
          <AddIcon />
          <Info>{t("Add PYVs")}</Info>
        </IconButton>
      ) : editVideoAuthResponse?.isLoading ? (
        <CircularProgress size="20px" sx={{ mt: "25px" }} />
      ) : (
        <Info> Something went wrong </Info>
      )}
    </Box>
  );
};

export default PyvVideo;
